<template>
  <div class="height-100 max_box">
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="content_box">
        <img class="img1" src="../../assets/img/lk.png" alt="" />
        <p class="name_title">灯塔黑桃老K</p>
        <div class="info_wrapper">
          <div class="yanbao">
            <img src="../../assets/img/dt_text.png" alt="" />
          </div>
          <p>
            {{ date.split("-").join(".") }}
          </p>
          <ul>
            <li>
              <img src="../../assets/img/text_1.png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/text_2.png" alt="" />
            </li>
            <li>
              <img src="../../assets/img/text3.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/dtlogo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card2"
    >
      <div class="top_title">
        <p>MACD<span class="bg_red">金叉</span>·选股</p>
        <p>{{ date.split("-").join(".") }}</p>
      </div>
      <div class="img_box">
        <img src="../../assets/img/kt1.png" alt="" />
      </div>
      <div class="stock_box" id="card2_box">
        <ul>
          <li
            v-for="(item, index) in stockArr1"
            :key="index"
            :class="{
              bg_red: colorArr1[index] > 0,
              bg_green: colorArr1[index] <= 0,
            }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card3"
    >
      <div class="top_title">
        <p>MACD<span class="bg_red">底</span>背离·选股</p>
        <p>{{ date.split("-").join(".") }}</p>
      </div>
      <div class="img_box">
        <img src="../../assets/img/kt2.png" alt="" />
      </div>
      <div class="stock_box" id="card3_box">
        <ul>
          <li
            v-for="(item, index) in stockArr2"
            :key="index"
            :class="{
              bg_red: colorArr2[index] > 0,
              bg_green: colorArr2[index] <= 0,
            }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card4"
    >
      <div class="top_title">
        <p>KDJ<span class="bg_red">金叉</span>·选股</p>
        <p>{{ date.split("-").join(".") }}</p>
      </div>
      <div class="img_box">
        <img src="../../assets/img/kt4.png" alt="" />
      </div>
      <div class="stock_box" id="card4_box">
        <ul>
          <li
            v-for="(item, index) in stockArr4"
            :key="index"
            :class="{
              bg_red: colorArr4[index] > 0,
              bg_green: colorArr4[index] <= 0,
            }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card5"
    >
      <div class="top_title">
        <p>KDJ<span class="bg_red">底</span>背离·选股</p>
        <p>{{ date.split("-").join(".") }}</p>
      </div>
      <div class="img_box">
        <img src="../../assets/img/kt5.png" alt="" />
      </div>
      <div class="stock_box" id="card5_box">
        <ul>
          <li
            v-for="(item, index) in stockArr5"
            :key="index"
            :class="{
              bg_red: colorArr5[index] > 0,
              bg_green: colorArr5[index] <= 0,
            }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card6"
    >
      <div class="top_title">
        <p><span class="bg_red">金针</span>探底·选股</p>
        <p>{{ date.split("-").join(".") }}</p>
      </div>
      <div class="img_box">
        <img src="../../assets/img/kt6.png" alt="" />
      </div>
      <div class="stock_box" id="card6_box">
        <ul>
          <li
            v-for="(item, index) in stockArr6"
            :key="index"
            :class="{
              bg_red: colorArr6[index] > 0,
              bg_green: colorArr6[index] <= 0,
            }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="animate__animated animate__fadeIn duration none" id="card7">
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/htend.mp4"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import axios from "axios";
export default {
  name: "Template58",
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      date: "",
      // card4
      stockArr4: [],
      stockCodeArr4: [],
      secocode4: "",
      colorArr4: [],
      //card2
      stockArr1: [],
      stockCodeArr1: [],
      secocode1: "",
      colorArr1: [],
      //card3
      stockArr2: [],
      stockCodeArr2: [],
      secocode2: "",
      colorArr2: [],

      //card5
      stockArr5: [],
      stockCodeArr5: [],
      secocode5: "",
      colorArr5: [],

      //card6
      stockArr6: [],
      stockCodeArr6: [],
      secocode6: "",
      colorArr6: [],
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
  },
  computed: {
    allReady() {
      const { date } = this;
      return {
        date,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    allReady(val) {
      if (val.date.length > 0) {
        if (this.ip && this.port) {
          console.log(666);
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordstartPc(this.newIpPort, params)
            .then(({ code }) => {})
            .catch((err) => {
              console.log(err);
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
          this.load();
        } else {
          // alert("没获取到ip,port");
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      let params = {
        model_id: 58,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 58,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getDataPC(params)
        .then(async ({ data, code }) => {
          if (code == 200) {
            //card2
            await this.getStockSelector1(
              data.chart_data_2[0].url,
              data.chart_data_2[0].payload
            );

            await this.getStockColor1(
              data.chart_data_2[1].url,
              data.chart_data_2[1].payload
            );

            //card3
            await this.getStockSelector2(
              data.chart_data_3[0].url,
              data.chart_data_3[0].payload
            );

            await this.getStockColor2(
              data.chart_data_3[1].url,
              data.chart_data_3[1].payload
            );

            //card4
            await this.getStockSelector4(
              data.chart_data_1[0].url,
              data.chart_data_1[0].payload
            );

            await this.getStockColor4(
              data.chart_data_1[1].url,
              data.chart_data_1[1].payload
            );

            //card5
            await this.getStockSelector5(
              data.chart_data_4[0].url,
              data.chart_data_4[0].payload
            );

            await this.getStockColor5(
              data.chart_data_4[1].url,
              data.chart_data_4[1].payload
            );

            //card6
            await this.getStockSelector6(
              data.chart_data_5[0].url,
              data.chart_data_5[0].payload
            );

            await this.getStockColor6(
              data.chart_data_5[1].url,
              data.chart_data_5[1].payload
            );
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },

    //card4  KDJ金叉·选股
    async getStockSelector4(url, params) {
      try {
        const urlParams1 = encodeURIComponent(url);
        const urlParams2 = encodeURIComponent(JSON.stringify(params));
        const newUrl =
          this.newIpPort +
          "/dtpostapiproxy/?api=" +
          urlParams1 +
          "&payloads=" +
          urlParams2;
        let { data } = await axios.get(newUrl);
        // console.log(data);
        let dataObj = data.stIAPickStockBatch.vIAPickStock;
        this.date = data.stIAPickStockBatch.sUpdateTime;
        // console.log(dataObj);
        this.stockArr4 = dataObj.map((v) => {
          return v.vValue[0];
        });
        // console.log(this.stockArr4);
        this.stockCodeArr4 = dataObj.map((v) => {
          return v.stSecInfo.sDtSecCode;
        });
        // console.log(this.stockCodeArr4);
        this.secocode4 = this.stockCodeArr4.join("|");
      } catch (err) {
        console.log(err);
      }
    },

    async getStockColor4(url, params) {
      const newUrl =
        url +
        "seccode=" +
        this.secocode4 +
        "&" +
        "action=squote&" +
        "AID=1362106";
      // console.log(newUrl);
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vSecSimpleQuote;
        // console.log(dataObj);
        this.colorArr4 = dataObj.map((v) => {
          return ((v.fNow - v.fClose) / v.fClose) * 100;
        });
        // console.log(this.colorArr4);
      }
    },

    //card2  MACD金叉·选股
    async getStockSelector1(url, params) {
      try {
        const urlParams1 = encodeURIComponent(url);
        const urlParams2 = encodeURIComponent(JSON.stringify(params));
        const newUrl =
          this.newIpPort +
          "/dtpostapiproxy/?api=" +
          urlParams1 +
          "&payloads=" +
          urlParams2;
        let { data } = await axios.get(newUrl);
        let dataObj = data.stIAPickStockBatch.vIAPickStock;
        this.date = data.stIAPickStockBatch.sUpdateTime;
        this.stockArr1 = dataObj.map((v) => {
          return v.vValue[0];
        });
        this.stockCodeArr1 = dataObj.map((v) => {
          return v.stSecInfo.sDtSecCode;
        });
        this.secocode1 = this.stockCodeArr1.join("|");
      } catch (err) {
        console.log(err);
      }
    },

    async getStockColor1(url, params) {
      console.log(this.secocode1);
      params.seccode = this.secocode1;
      console.log(params);
      const newUrl =
        url +
        "seccode=" +
        this.secocode1 +
        "&" +
        "action=squote&" +
        "AID=1362106";
      console.log(newUrl);
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vSecSimpleQuote;
        console.log(dataObj);
        this.colorArr1 = dataObj.map((v) => {
          return ((v.fNow - v.fClose) / v.fClose) * 100;
        });
      }
    },

    //card3  MACD底背离·选股
    async getStockSelector2(url, params) {
      try {
        const urlParams1 = encodeURIComponent(url);
        const urlParams2 = encodeURIComponent(JSON.stringify(params));
        const newUrl =
          this.newIpPort +
          "/dtpostapiproxy/?api=" +
          urlParams1 +
          "&payloads=" +
          urlParams2;
        let { data } = await axios.get(newUrl);
        // console.log(data);
        let dataObj = data.stIAPickStockBatch.vIAPickStock;
        this.date = data.stIAPickStockBatch.sUpdateTime;
        // console.log(dataObj);
        this.stockArr2 = dataObj.map((v) => {
          return v.vValue[0];
        });
        // console.log(this.stockArr2);
        this.stockCodeArr2 = dataObj.map((v) => {
          return v.stSecInfo.sDtSecCode;
        });
        // console.log(this.stockCodeArr2);
        this.secocode2 = this.stockCodeArr2.join("|");
      } catch (err) {
        console.log(err);
      }
    },

    async getStockColor2(url, params) {
      const newUrl =
        url +
        "seccode=" +
        this.secocode2 +
        "&" +
        "action=squote&" +
        "AID=1362106";
      console.log(newUrl);

      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vSecSimpleQuote;
        console.log(dataObj);
        this.colorArr2 = dataObj.map((v) => {
          return ((v.fNow - v.fClose) / v.fClose) * 100;
        });
        // console.log(this.colorArr2);
      }
    },
    //card5  MACD金叉·选股
    async getStockSelector5(url, params) {
      try {
        const urlParams1 = encodeURIComponent(url);
        const urlParams2 = encodeURIComponent(JSON.stringify(params));
        const newUrl =
          this.newIpPort +
          "/dtpostapiproxy/?api=" +
          urlParams1 +
          "&payloads=" +
          urlParams2;
        let { data } = await axios.get(newUrl);
        let dataObj = data.stIAPickStockBatch.vIAPickStock;
        this.date = data.stIAPickStockBatch.sUpdateTime;
        this.stockArr5 = dataObj.map((v) => {
          return v.vValue[0];
        });
        this.stockCodeArr5 = dataObj.map((v) => {
          return v.stSecInfo.sDtSecCode;
        });
        this.secocode5 = this.stockCodeArr5.join("|");
      } catch (err) {
        console.log(err);
      }
    },

    async getStockColor5(url, params) {
      const newUrl =
        url +
        "seccode=" +
        this.secocode5 +
        "&" +
        "action=squote&" +
        "AID=1362106";
      console.log(newUrl);

      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vSecSimpleQuote;
        console.log(dataObj);
        this.colorArr5 = dataObj.map((v) => {
          return ((v.fNow - v.fClose) / v.fClose) * 100;
        });
      }
    },

    //card6  MACD金叉·选股
    async getStockSelector6(url, params) {
      try {
        const urlParams1 = encodeURIComponent(url);
        const urlParams2 = encodeURIComponent(JSON.stringify(params));
        const newUrl =
          this.newIpPort +
          "/dtpostapiproxy/?api=" +
          urlParams1 +
          "&payloads=" +
          urlParams2;
        let { data } = await axios.get(newUrl);
        // console.log(data);
        let dataObj = data.stIAPickStockBatch.vIAPickStock;
        this.date = data.stIAPickStockBatch.sUpdateTime;
        // console.log(dataObj);
        this.stockArr6 = dataObj.map((v) => {
          return v.vValue[0];
        });
        // console.log(this.stockArr2);
        this.stockCodeArr6 = dataObj.map((v) => {
          return v.stSecInfo.sDtSecCode;
        });
        // console.log(this.stockCodeArr2);
        this.secocode6 = this.stockCodeArr6.join("|");
      } catch (err) {
        console.log(err);
      }
    },

    async getStockColor6(url, params) {
      // console.log(this.secocode);
      const newUrl =
        url +
        "seccode=" +
        this.secocode6 +
        "&" +
        "action=squote&" +
        "AID=1362106";
      console.log(newUrl);

      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vSecSimpleQuote;
        console.log(dataObj);
        this.colorArr6 = dataObj.map((v) => {
          return ((v.fNow - v.fClose) / v.fClose) * 100;
        });
        // console.log(this.colorArr2);
      }
    },

    endRecord() {
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 37,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: {},
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    load() {
      // this.$refs.audio1.play();

      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 1000);

      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        $("#card2_box").animate(
          {
            scrollTop:
              $("#card2_box ul").eq(0).height() - $("#card2_box").height(),
          },
          5000
        );
        // this.$refs.audio2.play();
      }, 2000); //间隔

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (5 + 5) * 1000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        $("#card3_box").animate(
          {
            scrollTop:
              $("#card3_box ul").eq(0).height() - $("#card3_box").height(),
          },
          5000
        );
        // this.$refs.audio3.play();
      }, (3 + 5) * 1000); //间隔

      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (3 + 5 + 5) * 1000);

      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        $("#card4_box").animate(
          {
            scrollTop:
              $("#card4_box ul").eq(0).height() - $("#card4_box").height(),
          },
          5000
        );
        // this.$refs.audio4.play();
      }, (4 + 5 + 5) * 1000); //间隔

      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (4 + 5 + 5 + 5) * 1000);

      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        $("#card5_box").animate(
          {
            scrollTop:
              $("#card5_box ul").eq(0).height() - $("#card5_box").height(),
          },
          5000
        );
        // this.$refs.audio5.play();
      }, (5 + 5 + 5 + 5) * 1000); //间隔

      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (5 + 5 + 5 + 5 + 5) * 1000);

      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        $("#card6_box").animate(
          {
            scrollTop:
              $("#card6_box ul").eq(0).height() - $("#card6_box").height(),
          },
          5000
        );
        // this.$refs.audio6.play();
      }, (6 + 5 + 5 + 5 + 5) * 1000); //间隔

      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (6 + 5 + 5 + 5 + 5 + 5) * 1000);

      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        this.$refs.endVideo.play();
        // this.$refs.audio7.play();
      }, (7 + 5 + 5 + 5 + 5 + 5) * 1000);

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 37000);
      }
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.max_box {
  font-size: 12px;
  background: #fff;
  font-family: "HarmonyOS_Sans_SC_Regular";
  overflow: hidden;
}
.card {
  background: url("../../assets/img/htbg2.png") no-repeat center center;
  background-size: auto;
  box-sizing: border-box;
  padding: 83px 47px;
}
.logo-box {
  width: 405px;
  bottom: 18%;
}

#card1 {
  background: url("../../assets/img/dtht_bg.png") no-repeat center center;
  background-size: contain;
  .img1 {
    display: block;
    position: absolute;
    top: 5%;
    left: 285px;
  }
  .name_title {
    font-size: 36px;
    font-style: italic;
    font-weight: 800;
    margin-top: 25%;
    text-align: center;
    margin-bottom: 83px;
    color: #2b2b2b;
  }

  .info_wrapper {
    .yanbao {
      width: 304.5px;
      margin: 4px auto;
      img {
        width: 100%;
        margin: 10px auto;
      }
    }
    p:first-of-type {
      text-align: center;
      color: #3d3d3d;
      font-size: 40px;
      margin-bottom: 40px;
      font-weight: bold;
    }
    ul {
      width: 48%;
      margin: auto;
      text-align: center;

      li {
        font-size: 36px;
        font-weight: bold;
        color: #3c3c3c;
        margin-bottom: 20px;
        position: relative;
      }
    }
  }
}

.common_card {
  .top_title {
    p:first-of-type {
      float: left;
      color: #191919;
      font-size: 40px;
    }
    p:nth-of-type(2) {
      float: right;
      color: #3d3d3d;
      font-size: 28px;
      line-height: 63px;
    }
  }
  .bg_red {
    color: #e33122;
  }
  .img_box {
    width: 540px;
    height: 314px;
    margin: 128px auto 22px;
  }
  .stock_box {
    width: 540px;
    margin: auto;
    max-height: 519px;
    overflow: auto;
    ul {
      width: 100%;
      overflow: hidden;
      // animation: rowup 5s infinite;
      li {
        float: left;
        font-size: 26px;
        color: #ffffff;
        width: 27%;
        height: 45px;
        line-height: 45px;
        border-radius: 30px;
        text-align: center;
        margin-bottom: 20px;
        margin-right: 46px;
      }
      li:nth-of-type(3n) {
        margin-right: 0px;
      }
    }

    .bg_red {
      background: #fe5f5f;
    }
    .bg_green {
      background-color: #70ec6a;
    }
  }
}
// @keyframes rowup {
//   0% {
//     -webkit-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//   }
//   100% {
//     -webkit-transform: translate3d(0, -260px, 0);
//     transform: translate3d(0, -260px, 0);
//     display: none;
//   }
// }
</style>